import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Playstore from "../../img/google-play-badge.png";
import Left from "../../img/chevron-left.svg";
import Sparkle from "../../img/sparkle.png";
import Profile from "../../img/profile.png";
import Down from "../../img/chevron-down.png";
import "./header.css";
import { useTranslation } from "react-i18next";
import { isUserLoggedIn } from "../../services";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import BurgerMenu from "./burger";

function Header({
  showBack = false,
  subscription = true,
  mealPlan = true,
  signUp = true,
  profile = true,
  login = false,
}) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(
    window.location.pathname.split("/")[1]
  );
  const [userName, setUserName] = useState("");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setIsLoggedIn(true);

        if (user.displayName) {
          setUserName(user.displayName);
        }
      } else {
        setIsLoggedIn(false);
      }
    });
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <header>
      <img
        style={{
          visibility: showBack ? "visible" : "hidden",
        }}
        src={Left}
        className="chevron-left"
        onClick={() => {
          if (document.referrer.includes(window.location.hostname)) {
            navigate(-1);
          } else {
            navigate("/");
          }
        }}
      />
      <div className="menu">
        <div className="brand-name">
          <a
            href={isLoggedIn ? "/dashboard" : "/"}
            target="_self"
            rel="noreferrer"
          >
            Healthzify
          </a>
        </div>
        {isLoggedIn && !isMobile && (
          <ul>
            <li
              className={currentPage === "dashboard" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                setCurrentPage("dashboard");
                navigate("/dashboard");
              }}
            >
              Dashboard
            </li>
            <li
              className={currentPage === "my-plan" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                window.gtag &&
                  window.gtag("event", "click", {
                    event_category: "button",
                    event_label: "my plan",
                    referring_page: "header",
                  });
                setCurrentPage("my-plan");
                navigate("/my-plan");
              }}
            >
              My Plan
            </li>
            {/* <li>Likes</li> */}
          </ul>
        )}
      </div>
      {/* <a
        href="https://play.google.com/store/apps/details?id=com.healthzify"
        target="_blank"
        rel="noreferrer"
        className="playstore"
      >
        <img src={Playstore} alt="Get the App" />
      </a> */}
      {/* <div>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('hi')}>हिन्दी</button>
    </div> */}
      {!isMobile && (
        <span>
          {signUp && isLoggedIn === false && (
            <a
              className="sign-up-btn"
              href="/signup"
              onClick={(e) => {
                e.preventDefault();
                window.gtag &&
                  window.gtag("event", "click", {
                    event_category: "button",
                    event_label: "sign up",
                    referring_page: "header",
                  });
                setCurrentPage("signup");
                navigate("/signup");
              }}
            >
              Sign Up
            </a>
          )}
          {login && isLoggedIn === false && (
            <a
              className="sign-up-btn"
              href="/login"
              onClick={(e) => {
                e.preventDefault();
                window.gtag &&
                  window.gtag("event", "click", {
                    event_category: "button",
                    event_label: "log in",
                    referring_page: "header",
                  });
                setCurrentPage("login");
                navigate("/login");
              }}
            >
              Log In
            </a>
          )}
          <a
            className="header-key-button meal-plan"
            href="/meal-plan"
            style={{ display: mealPlan ? "inline-block" : "none" }}
            onClick={(e) => {
              e.preventDefault();
              window.gtag &&
                window.gtag("event", "click", {
                  event_category: "button",
                  event_label: "ai meal plan",
                });
              setCurrentPage("meal-plan");
              navigate("/meal-plan");
            }}
          >
            <img src={Sparkle} />
            &nbsp;AI Meal Plan
          </a>
          <a
            className="subscribe"
            href="/subscribe"
            style={{ display: subscription ? "inline-bloack" : "none" }}
            onClick={(e) => {
              e.preventDefault();
              window.gtag &&
                window.gtag("event", "click", {
                  event_category: "button",
                  event_label: "home delivery",
                });
              setCurrentPage("subscribe");
              navigate("/subscribe");
            }}
          >
            {t("Home Delivery")}
          </a>
          {profile && isLoggedIn !== null && (
            <a
              className="profile"
              href=""
              onClick={(e) => e && e.preventDefault()}
            >
              {!isLoggedIn && <img src={Profile} />}

              {isLoggedIn ? (
                <span style={{ marginLeft: 20 }}>{userName}</span>
              ) : (
                ""
              )}
              <img className="icon-down" src={Down} />
              <ul>
                {!isLoggedIn && (
                  <>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        window.gtag &&
                          window.gtag("event", "click", {
                            event_category: "button",
                            event_label: "sign up",
                            referring_page: "profile header",
                          });
                        setCurrentPage("signup");
                        navigate("/signup");
                      }}
                    >
                      Sign Up
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        window.gtag &&
                          window.gtag("event", "click", {
                            event_category: "button",
                            event_label: "log in",
                            referring_page: "profile header",
                          });
                        setCurrentPage("login");
                        navigate("/login");
                      }}
                    >
                      Log In
                    </li>
                  </>
                )}
                {isLoggedIn && (
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.gtag &&
                        window.gtag("event", "click", {
                          event_category: "button",
                          event_label: "log out",
                        });
                      setCurrentPage("");
                      navigate("/signout");
                    }}
                  >
                    Log Out
                  </li>
                )}
              </ul>
            </a>
          )}
        </span>
      )}

      {isMobile && !showBack && (
        <>
          <BurgerMenu
            isOpen={isMenuOpen}
            setIsOpen={setMenuOpen}
            isLoggedIn={isLoggedIn}
          />
          <span
            className="mobile-menu"
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <i className="fa-solid fa-bars"></i>
          </span>
        </>
      )}
    </header>
  );
}

export default Header;
