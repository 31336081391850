import { useEffect } from "react";
import SignUpForm from "../../components/signup";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function SignUp() {
  const navigate = useNavigate();
  document.title = "Sign Up";
  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        navigate("/");
      }
    });
  }, []);

  return (
    <>
      <Header
        subscription={false}
        mealPlan={false}
        signUp={false}
        profile={false}
        login={true}
      />
      <div style={{ marginTop: 25, marginBottom: 25, minHeight: "60vh" }}>
        <SignUpForm />
      </div>
    </>
  );
}

export default SignUp;
