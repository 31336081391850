const Results = ({ plan = {}, status = "" }) => (
  <>
    {/* {status === "success" && (
      <div>
        <div id="meal-plan-results">
          <p>
            Your weekly plan includes the right mix of macros - carbohydrates,
            protein, fats, and fiber customised for your life. It also
            incorporates a healthy mix of micros like antioxidants, magnesium,
            iron etc that help you in sleep, muscle restoration etc.
          </p>
          {Object.keys(plan).map((day, index) => {
            return [
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].some((d) => d === day.toLowerCase()) ? (
              <div key={index}>
                <h3>{day}</h3>
                <label>Breakfast</label>
                <ul>
                  {plan[day]["Breakfast"].map((food, i) => {
                    return <li key={i}>{food}</li>;
                  })}
                </ul>
                <label>Lunch</label>
                <ul>
                  {plan[day]["Lunch"].map((food, i) => {
                    return <li key={i}>{food}</li>;
                  })}
                </ul>
                <label>Snacks</label>
                <ul>
                  {plan[day]["Snacks"].map((food, i) => {
                    return <li key={i}>{food}</li>;
                  })}
                </ul>
                <label>Dinner</label>
                <ul>
                  {plan[day]["Dinner"].map((food, i) => {
                    return <li key={i}>{food}</li>;
                  })}
                </ul>
                {plan[day]["total"] && (
                  <>
                    <label>Nutrition for the day</label>
                    <ul>
                      {plan[day]["total"]["calories"] && (
                        <li>
                          Total Calories: {plan[day]["total"]["calories"]} Cal
                        </li>
                      )}

                      <li>Protein: {plan[day]["total"]["protein"]}g</li>
                      <li>
                        Carbohydrates: {plan[day]["total"]["carbohydrates"]}g
                      </li>
                      <li>Fats: {plan[day]["total"]["fats"]}g</li>
                    </ul>
                  </>
                )}
              </div>
            ) : (
              <></>
            );
          })}
          <p>
            Adjust or replace some items if it helps make it easier for you to
            source and adhere.
            <br />
            <br />
            Drink at least 2 litres of water daily on a regular day where you do
            not have lot of physical activity. If you have a higher activity on
            a given day, you will neeed to take in at least 2.5-3L of water.{" "}
          </p>
        </div>
        <p className="search-alt">
          For alternatives, just{" "}
          <a style={{ textDecoration: "underline" }} href="/">
            search
          </a>{" "}
          our catalog for the product that you need an alternative for and find
          the alternatives on the product page.
          <br />
          <br />
          You can also mail us at support@healthzify.com and we would love to
          help you out anytime.
        </p>
      </div>
    )} */}
    {status === "error" && (
      <div>
        <p>
          <br />
          Sorry! We are currently unable to generate the plan for you.
          <br />
          Please{" "}
          <a style={{ textDecoration: "underline" }} href="/meal-plan">
            try again
          </a>{" "}
          or come back after some time.
          <br />
          <br />
          If the problem persists, mail us at support@healthzify.com and we will
          send your plan via email.
        </p>
      </div>
    )}
  </>
);

export default Results;
