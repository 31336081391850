import { motion } from "framer-motion";

const Button = ({ click, children, disabled = false }) => {
  // Variants for animation states
  const buttonVariants = {
    hover: {
      scale: 1.1, // Enlarges the button by 10%
      textShadow: "0px 0px 8px rgb(255,255,255)",
      boxShadow: "0px 0px 8px rgb(255,255,255)",
    },
    tap: {
      scale: 0.95, // Compresses the button slightly
      backgroundColor: "#D1D1D1", // Change background color on press
    },
    disabled: {
      scale: 1, // Normal scale
      backgroundColor: "#CCC", // Greyed out look
      color: "#666", // Dim text color
      cursor: "not-allowed",
      boxShadow: "none",
      textShadow: "none",
    },
  };

  return (
    <motion.button
      variants={buttonVariants}
      whileHover={!disabled ? "hover" : ""}
      whileTap={!disabled ? "tap" : ""}
      animate={disabled ? "disabled" : ""}
      style={{
        padding: "10px 20px",
        fontSize: "16px",
        color: "#FFF",
        backgroundColor: "#007BFF",
        border: "none",
        borderRadius: "5px",
        outline: "none",
        cursor: "pointer",
        transition: "background-color 0.3s",
      }}
      disabled={disabled}
      onClick={click}
    >
      {children}
    </motion.button>
  );
};

export default Button;
