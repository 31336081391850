import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import "./index.css";
import BodyInfo from "./bodyinfo";
import Activity from "./activity";
import MealPreference from "./meal-preference";
import Results from "./results";
import Goal from "./goal";
import GetEmail from "./email";
import Newsletter from "../home/newsletter";
import SignUpForm from "../../components/signup";
import LoginForm from "../../components/login";

import MealPlanHeader from "../../img/meal-plan.png";
import MealPlanReadyHeader from "../../img/meal-plan-ready.png";
import CreatingPlan from "../../img/creating-plan.gif";

import { generateMyMealPlan, subscribeToNewsletter } from "../../services";
import { updateEmail } from "firebase/auth";

function MealPlan() {
  const [currentStep, setCurrentStep] = useState(1);
  const [userDetails, setUserDetails] = useState({});
  const [plan, setPlan] = useState({});
  const [planGenerate, setPlanGenerate] = useState();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loginState, setLoginState] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setLoginState("signup");
      }
    });

    document.title = "Your AI Meal Plan";
    if (localStorage && localStorage.getItem("meal-plan-details")) {
      const savedInfo = JSON.parse(localStorage.getItem("meal-plan-details"));
      setUserDetails(savedInfo);
      setCurrentStep(5);
      //getMealPlan(savedInfo);
      localStorage.removeItem("meal-plan-details");
    }

    return () => localStorage.removeItem("meal-plan-details");
  }, []);

  useEffect(() => {
    if (currentStep === 5 && isAuthenticated && userDetails) {
      getMealPlan(userDetails);
    }
  }, [currentStep, isAuthenticated, userDetails]);

  const updateInput = (info) => {
    setUserDetails({ ...userDetails, ...info });
  };

  const nextStep = (e) => {
    e && e.preventDefault();
    if (currentStep === 4) {
      if (isAuthenticated) {
        setCurrentStep(currentStep + 1);
        window.gtag &&
          window.gtag("event", "click", {
            event_category: "button",
            event_label: "generating meal plan",
          });
      } else {
        window.gtag &&
          window.gtag("event", "click", {
            event_category: "button",
            event_label: "signup login ai meal plan",
          });
        // updateEmail(userDetails.email);
        // getMealPlan();
        cacheInfo();
      }
    } //else {
    setCurrentStep(currentStep + 1);
    //}
  };

  function cacheInfo() {
    localStorage &&
      localStorage.setItem("meal-plan-details", JSON.stringify(userDetails));
  }

  function updateEmail(email) {
    subscribeToNewsletter(email);
    let details = userDetails;
    delete details.email;
    setUserDetails(details);
  }

  function getMealPlan(userDetails) {
    setPlanGenerate("loading");
    generateMyMealPlan({ userDetails })
      .then((response) => {
        // setPlan(response);
        // setPlanGenerate("success");

        navigate("/my-plan");
      })
      .catch((err) => {
        setPlanGenerate("error");
        setCurrentStep(currentStep + 1);
      });
  }

  return (
    <>
      <Header showBack={true} mealPlan={false} />
      <div className="page-container">
        <div id="meal-form">
          <img
            src={currentStep !== 6 ? MealPlanHeader : MealPlanReadyHeader}
            alt="Meal Plan"
          />
          {currentStep !== 5 && !planGenerate && (
            <div className="steps">
              <div className="step-indicator">
                <div className={`step-container`}>
                  <span
                    className={`step ${currentStep === 1 ? "current" : ""} ${
                      currentStep > 1 ? "completed" : ""
                    }`}
                  >
                    <span
                      style={{ display: currentStep > 1 ? "none" : "initial" }}
                    >
                      1
                    </span>
                  </span>
                </div>
                <div className={`step-container`}>
                  <span
                    className={`step ${currentStep === 2 ? "current" : ""} ${
                      currentStep > 2 ? "completed" : ""
                    }`}
                  >
                    <span
                      style={{ display: currentStep > 2 ? "none" : "initial" }}
                    >
                      2
                    </span>
                  </span>
                </div>
                <div className={`step-container`}>
                  <span
                    className={`step ${currentStep === 3 ? "current" : ""} ${
                      currentStep > 2 ? "completed" : ""
                    }`}
                  >
                    <span
                      style={{ display: currentStep > 3 ? "none" : "initial" }}
                    >
                      3
                    </span>
                  </span>
                </div>
                <div className={`step-container`}>
                  <span
                    className={`step ${currentStep === 4 ? "current" : ""}`}
                  >
                    <span
                      style={{ display: currentStep > 4 ? "none" : "initial" }}
                    >
                      4
                    </span>
                  </span>
                </div>
              </div>
              <div className="form-container">
                {currentStep === 1 && (
                  <BodyInfo setInfo={updateInput} next={nextStep} />
                )}
                {currentStep === 2 && (
                  <Activity setInfo={updateInput} next={nextStep} />
                )}
                {currentStep === 3 && (
                  <MealPreference setInfo={updateInput} next={nextStep} />
                )}
                {currentStep === 4 && (
                  <Goal setInfo={updateInput} next={nextStep} />
                )}
              </div>
            </div>
          )}
          {currentStep === 5 && !planGenerate && (
            <>
              {/* <GetEmail setInfo={updateInput} next={nextStep} /> */}
              {loginState === "login" && (
                <>
                  <LoginForm redirectUrl="https://www.healthzify.com/meal-plan" />
                  <div style={{ marginBottom: 15 }}>
                    <span>Not signed up yet? </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setLoginState("signup");
                      }}
                      style={{ textDecoration: "underline" }}
                    >
                      Sign Up
                    </a>
                  </div>
                </>
              )}
              {loginState === "signup" && (
                <>
                  <SignUpForm redirectUrl="https://www.healthzify.com/meal-plan" />
                  <div style={{ marginBottom: 15 }}>
                    <span>Already have an account? </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setLoginState("login");
                      }}
                      style={{ textDecoration: "underline", marginBottom: 15 }}
                    >
                      Log in
                    </a>
                  </div>
                </>
              )}
            </>
          )}
          {currentStep === 6 && <Results plan={plan} status={planGenerate} />}

          {planGenerate === "loading" && (
            <img src={CreatingPlan} alt="Creating Plan..." />
          )}
        </div>
        {/* {currentStep === 6 && planGenerate !== "error" && (
          <div id="newsletter">
            <Newsletter />
          </div>
        )} */}
      </div>
    </>
  );
}

export default MealPlan;
