import { subscribeToNewsletter } from "../../services";
import "./newsletter.css";

const Newsletter = () => {
  const subscribeNewsletter = (e) => {
    e.preventDefault();
    document.getElementById("message").textContent = "";
    let email = document.getElementById("email").value;
    if (validateEmail(email)) {
      window.gtag &&
        window.gtag("event", "click", {
          event_category: "button",
          event_label: "subscribe newsletter",
        });

      // Save the email to Firestore
      subscribeToNewsletter(email)
        .then(() => {
          document.getElementById("message").textContent =
            "Thank you for subscribing!";
          document.getElementById("message").style.color = "green";
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          document.getElementById("message").textContent =
            "Error subscribing. Please try again.";
          document.getElementById("message").style.color = "red";
        });
    } else {
      document.getElementById("message").textContent =
        "Please enter a valid email address.";
      document.getElementById("message").style.color = "red";
    }
  };

  function validateEmail(email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <>
      <div className="newsletter-container">
        <h3>Subscribe to Our Newsletter</h3>
        <p>
          Get awesome weekly recommendations, and latest insights about food,
          nutrition, longevity and much more.
        </p>
        <form id="newsletter-form" className="newsletter-form">
          <div className="form-control">
            <i className="fa fa-envelope" aria-hidden="true"></i>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              required
            />
          </div>
          <button type="submit" className="btn" onClick={subscribeNewsletter}>
            Subscribe
          </button>
        </form>
        <div id="message"></div>
      </div>
    </>
  );
};

export default Newsletter;
