import { useEffect } from "react";
import LoginForm from "../../components/login";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function LogIn() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Log In";
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        navigate("/");
      }
    });
  }, []);

  return (
    <>
      <Header subscription={false} mealPlan={false} profile={false} />
      <div style={{ marginTop: 25, marginBottom: 25, minHeight: "60vh" }}>
        <LoginForm />
      </div>
    </>
  );
}

export default LogIn;
