const MealPreference = ({ setInfo, next }) => (
  <form onSubmit={next}>
    <div>
      <label>Dietary Preference</label>
      <br />
      <input
        type="radio"
        id="v"
        name="food"
        value="veg"
        required
        onChange={(e) => setInfo({ diet_preference: e.target.value })}
      />
      <label htmlFor="v">Veg Only</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <input
        type="radio"
        id="nv"
        name="food"
        value="nonveg"
        required
        onChange={(e) => setInfo({ diet_preference: e.target.value })}
      />
      <label htmlFor="nv">Veg &amp; Non Veg</label>
    </div>
    <button type="submit">Next</button>
  </form>
);

export default MealPreference;
