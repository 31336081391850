import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const StatusBanner = ({ messages, type }) => {
  // If errors is not an array or undefined, convert it into an array
  const [visible, setVisible] = useState(!!messages);
  const status = Array.isArray(messages) ? messages : [messages];

  useEffect(() => {
    if (messages) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 3000); // Error disappears after 3000 milliseconds (3 seconds)
      return () => clearTimeout(timer);
    }
  }, [messages]);

  if (!visible) return null;

  return (
    <AnimatePresence>
      {status && status.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: 50, scale: 0.3 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
          style={{
            ...styles.container,
            backgroundColor: type === "error" ? "rgba(255, 0, 0)" : "#63a363",
          }}
        >
          {status.map((message, index) => (
            <motion.div key={index} style={styles.message}>
              {message}
            </motion.div>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

// Styling for the error messages container
const styles = {
  container: {
    position: "fixed", // Fixed at the top
    top: 20,
    right: "25px",
    transform: "translateX(-50%)",
    zIndex: 1000,
    color: "white",
    padding: "15px 20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    width: "auto",
    maxWidth: "90%", // Prevents the box from being too wide on smaller screens
    textAlign: "center",
  },
  message: {
    fontSize: "16px",
    fontWeight: "bold",
  },
};

export default StatusBanner;
