import { initializeApp } from "firebase/app";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import "firebase/functions";
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  updateProfile,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore/lite";
import {
  query,
  where,
  serverTimestamp,
  getDoc,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import FirebaseConfig from "../configs/firebase";
import { removeURLParameters, extractPath } from "../utils";

const app = initializeApp(FirebaseConfig);
const auth = getAuth();

// Initialize Firebase Functions
const functions = getFunctions(app);
// // Point to Local Emulator for Local Testing
if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

if (isSignInWithEmailLink(auth, window.location.href)) {
  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
  let email = window.localStorage.getItem("emailForSignIn");
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt("Please provide your email for confirmation");
  }

  // The client SDK will parse the code from the link for you.
  signInWithEmailLink(auth, email, window.location.href)
    .then((result) => {
      // Clear email from storage.
      window.localStorage.removeItem("emailForSignIn");
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:

      if (result._tokenResponse.isNewUser) {
        const displayName = window.localStorage.getItem("userName");
        if (displayName) {
          createUser(email, displayName);
          updateUser({ displayName });
          window.localStorage.removeItem("userName");
        }
      }
      let currentUrl = window.location.href;
      let parametersToRemove = ["apiKey", "oobCode", "mode", "lang"];
      let updatedUrl = removeURLParameters(currentUrl, parametersToRemove);

      // Update the URL without reloading the page
      window.history.replaceState({}, document.title, updatedUrl);
    })
    .catch((error) => {
      alert("Unable to login. Your link may have expired. Please try again.");
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
    });
}

const db = getFirestore(app);
const productsRef = collection(db, "products");
const ingredientsRef = collection(db, "ingredients");

const getAuthToken = async () => {
  const user = auth.currentUser;

  let idToken;
  if (user) {
    const token = await user.getIdToken();
    return token;
  }
  return;
};

const getData = async (query) => {
  try {
    if (!query) {
      return;
    }
    const results = await getDocs(query);
    let data = null;
    if (results.forEach) {
      data = [];
      results.forEach((result) =>
        data.push({ id: result.id, ...result.data() })
      );
    } else if (results.data) {
      data = { id: results.id, ...results.data() };
    }
    return data;
  } catch (e) {
    if (e.code && e.code === "permission-denied") {
      //   signOut();
      return;
    }
    throw new Error(e);
  }
};

export const getAllProducts = async () => {
  const results = await getData(productsRef);
  return results;
};

export const getAllIngredients = async () => {
  const results = await getData(ingredientsRef);
  return results;
};

export const getProductsByName = async (name = "") => {
  try {
    const getProductByName = httpsCallable(functions, "getProductByName");
    const results = await getProductByName({ query: name });
    return results && results.data;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }

  // try {
  //   const results = await getAllProducts();
  //   const products = results.filter((product) => {
  //     let productName = product.name.replace(/[^a-z0-9 ]/gi, "");
  //     productName = productName.split("-").join(" ");
  //     return productName.toLowerCase().indexOf(name.toLowerCase()) > -1
  //       ? true
  //       : false;
  //   });
  //   return products;
  // } catch (e) {
  //   console.log(e);
  //   throw new Error(e);
  // }
};

//any matching attribute matches query
export const getProductsByQuery = async (query = "") => {
  try {
    const results = await getAllProducts();
    const products = results.filter((product) => {
      let productName = product.name.replace(/[^a-z0-9 ]/gi, "");
      productName = productName.split("-").join(" ");
      let aiVerdict =
        product.ai_verdict && product.ai_verdict.replace(/[^a-z0-9 ]/gi, "");
      if (aiVerdict) {
        aiVerdict = aiVerdict.split("-").join(" ");
      }
      let nutrition_highlights =
        product.nutrition_highlights && product.nutrition_highlights.join(" ");
      if (nutrition_highlights) {
        nutrition_highlights = nutrition_highlights.split("-").join(" ");
      }
      let tags = product.tags && product.tags.join(" ");
      if (tags) {
        tags = tags.split("-").join(" ");
      }
      return productName.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        (aiVerdict &&
          aiVerdict.toLowerCase().indexOf(query.toLowerCase()) > -1) ||
        (tags && tags.toLowerCase().indexOf(query.toLowerCase()) > -1) ||
        (nutrition_highlights &&
          nutrition_highlights.toLowerCase().indexOf(query.toLowerCase()) > -1)
        ? true
        : false;
    });
    return products;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

const filterProductsBy = (products = [], value = "", key = "") => {
  try {
    return products.filter((product) =>
      product[key].indexOf(value) > -1 ? true : false
    );
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

const filterIngredientsBy = (ingredients = [], value = "", key = "") => {
  try {
    //const results = await getAllIngredients();
    return ingredients.filter((ingredient) =>
      ingredient[key].indexOf(value) > -1 ? true : false
    );
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const getProductsByTags = async (tags = []) => {
  const results = await getData(
    query(
      productsRef,
      where(
        "tags",
        "array-contains-any",
        tags.map((tag) => tag.toLowerCase())
      )
    )
  );
  return results;
};

export const populateIngredients = async (product = null) => {
  if (product && product.ingredients) {
    const results = await getAllIngredients();
    const ingredients = await Promise.all(
      product.ingredients.map(async (item) => {
        const ingredient = filterIngredientsBy(results, item.id.id, "id")[0];
        // const ingredient = await getData(
        //   ingredientsRef.doc(
        //     item.id.id ? item.id.id : item.id._path.segments[1]
        //   )
        // );
        return {
          ...item,
          ...ingredient,
        };
      })
    );
    return ingredients;
  }
  return null;
};

export const populateOtherItems = async (product = null) => {
  if (product && product.other_contents) {
    const results = await getAllIngredients();
    const others = await Promise.all(
      product.other_contents.map(async (item) => {
        const others = filterIngredientsBy(results, item.id.id, "id")[0];
        // const others = await getData(
        //   ingredientsRef.doc(
        //     item.id.id ? item.id.id : item.id._path.segments[1]
        //   )
        // );
        return {
          ...item,
          ...others,
        };
      })
    );
    return others;
  }
  return null;
};

export const populateAlternatives = async (product = null) => {
  if (product && product.alternatives) {
    const results = await getAllProducts();
    const alternatives = await Promise.all(
      product.alternatives.map(async (item) => {
        return filterProductsBy(results, item.id, "id")[0];
        // await getData(
        //   query(productsRef, where(documentId(), "==", item.id))
        // );
      })
    );
    return alternatives;
  }
  return null;
};

export const populateProductInfo = async (product = {}) => {
  const [ingredients, otherContents, alternatives] = await Promise.all([
    populateIngredients(product),
    populateOtherItems(product),
    populateAlternatives(product),
  ]);
  return {
    ...product,
    ingredients,
    other_contents: otherContents,
    alternatives,
  };
};

export const subscribeToNewsletter = async (email = "") => {
  if (email) {
    const done = await addDoc(collection(db, "newsletter_subscriptions"), {
      email: email,
      timestamp: serverTimestamp(), // Using client-side timestamp for simplicity
    });

    return done;
  } else {
    return null;
  }
};

export const subscribeToService = async (name = "", email = "") => {
  if (name && email) {
    const done = await addDoc(collection(db, "service_subscriptions"), {
      name: name,
      email: email,
      timestamp: serverTimestamp(), // Using client-side timestamp for simplicity
    });

    return done;
  } else {
    return null;
  }
};

//via firebase functions backend
export const searchProducts = async (query = "") => {
  try {
    const searchProductsWeb = httpsCallable(functions, "searchProductsWeb");
    const results = await searchProductsWeb({ query: query });
    return results && results.data;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const generateMyMealPlan = async (userDetails = {}) => {
  try {
    const generateMealPlan = httpsCallable(functions, "generateMealPlan");
    const token = await getAuthToken();
    if (token) {
      const results = await generateMealPlan(userDetails, {
        headers: { Authorization: "Bearer " + token },
      });

      return results;
    } else {
      throw new Error("Not authenticated");
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const signUp = async (
  userDetails = {},
  url = "https://healthzify.com/dashboard"
) => {
  try {
    if (window.location.hostname === "localhost") {
      url = "http://localhost:3000" + extractPath(url);
    }
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url,
      // This must be true.
      handleCodeInApp: true,
      // iOS: {
      //   bundleId: 'com.example.ios'
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '12'
      // },
      // dynamicLinkDomain: 'example.page.link'
    };

    const result = await sendSignInLinkToEmail(
      auth,
      userDetails.email,
      actionCodeSettings
    );
    window.localStorage.setItem("emailForSignIn", userDetails.email);
    window.localStorage.setItem("userName", userDetails.name);
    return result && result.data;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const login = async (
  email,
  url = "https://healthzify.com/dashboard"
) => {
  try {
    const userExists = httpsCallable(functions, "checkUserExists");

    const results = await userExists({ email: email });

    if (results && results.data && results.data.status) {
      if (window.location.hostname === "localhost") {
        url = "http://localhost:3000" + extractPath(url);
      }
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url,
        // This must be true.
        handleCodeInApp: true,
        // iOS: {
        //   bundleId: 'com.example.ios'
        // },
        // android: {
        //   packageName: 'com.example.android',
        //   installApp: true,
        //   minimumVersion: '12'
        // },
        // dynamicLinkDomain: 'example.page.link'
      };

      const result = await sendSignInLinkToEmail(
        auth,
        email,
        actionCodeSettings
      );
      window.localStorage.setItem("emailForSignIn", email);
      return result && result.data;
    } else {
      throw new Error("invalid user");
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const updateUser = async (userDetails) => {
  updateProfile(auth.currentUser, userDetails);
};

export const createUser = async (email, name) => {
  try {
    const user = auth.currentUser;
    const userRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(userRef);

    if (!docSnap.exists()) {
      const userRef = doc(db, "users", user.uid);
      setDoc(userRef, {
        uid: user.uid,
        email,
        name,
        signup_mode: "web",
        createdAt: new Date().toISOString(),
      });
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const signOutUser = async () => {
  try {
    const result = await signOut(auth);

    return result && result.data;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const getCurrentUser = () => {
  return auth.currentUser;
};

export const getUserInfo = async () => {
  try {
    const user = auth.currentUser;

    const userRef = doc(db, "users", user.uid);
    const usrRef = await getDoc(userRef);

    return usrRef && usrRef.data();
  } catch (e) {
    throw new Error(e);
  }
};

// export const updateMealPlan = async (plan) => {
//   try {
//     const user = auth.currentUser;
//     const userRef = doc(db, "users", user.uid);
//     const res = await updateDoc(userRef, {
//       meal_plan: plan,
//     });

//     return res;
//   } catch (e) {
//     throw new Error(e);
//   }
// };

export const updateMealPlan = async (plan, day) => {
  try {
    const userExists = httpsCallable(functions, "updateMealPlan");
    const results = await userExists({ plan: plan, day: day });
    return results && results.data;
  } catch (e) {
    throw new Error(e);
  }
};
