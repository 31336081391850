import { useState, useEffect } from "react";
import { login } from "../../services";

import "./index.css";

function LogIn({
  completed = () => {},
  redirectUrl = "https://healthzify.com/dashboard",
}) {
  const [email, setEmail] = useState();
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginMailSent, setLoginMailSent] = useState(false);

  function loginUser(e) {
    e && e.preventDefault();
    setLoginError(false);
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      return;
    }
    setLoggingIn(true);
    login(email, redirectUrl)
      .then((done) => {
        completed();
        setLoginMailSent(true);
      })
      .catch((e) => {
        setLoginError(true);
      })
      .finally((done) => setLoggingIn(false));
  }

  return (
    <div className="container">
      <div id="login-form">
        <h2>Log In</h2>
        {!loginMailSent && (
          <form onSubmit={loginUser}>
            <div className="input-group">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email Id"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {loginError && (
              <>
                <div className="error">
                  Unable to Log In. Please try again.
                  <br />
                  If you do not have an account, please{" "}
                  <a href="/signup" style={{ textDecoration: "underline" }}>
                    Sign Up
                  </a>{" "}
                  first.
                </div>
                <br />
                <br />
              </>
            )}
            <button
              type="submit"
              className="subscribe-button"
              disabled={!email || loggingIn}
            >
              Log In
            </button>
            <br />
            <br />
          </form>
        )}
        {!loginMailSent && (
          <div className="signup">
            <span>Not signed up yet? </span>
            <a href="/signup">Sign Up</a>
          </div>
        )}
        {loginMailSent && (
          <>
            <p id="signup-email-sent">
              You will receive an email from Healthzify, to the email provided
              by you. Please click on the link in the email and open it in this
              browser to Log In.
            </p>
            <p>
              If you are facing any issues, please contact us at
              support@healthzify.com.
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default LogIn;
