import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOutUser } from "../../services";

function SignOut(){
    const navigate = useNavigate();
    useEffect(()=>{
        signOutUser();
    navigate("/");
    },[]);
    
    return <></>;
}

export default SignOut;