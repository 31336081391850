import { useState, useEffect } from "react";
import { signUp } from "../../services";

import "./index.css";

function SignUp({
  completed = () => {},
  redirectUrl = "https://healthzify.com/dashboard",
}) {
  const [signUpDetails, setSignUpDetails] = useState({});
  const [signingUp, setSigningUp] = useState(false);
  const [signUpError, setSignUpError] = useState(false);
  const [signUpMailSent, setSignUpMailSent] = useState(false);

  useEffect(() => {
    // if (window.localStorage.getItem("emailForSignUp")) {
    //   setSignUpDetails({
    //     ...signUpDetails,
    //     email: window.localStorage.getItem("emailForSignUp"),
    //   });
    // }
    // return () => {
    //   window.localStorage.setItem("emailForSignUp", email);
    // };
  }, []);

  function signUpUser(e) {
    e && e.preventDefault();
    setSignUpError(false);
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(signUpDetails.email)) {
      return;
    }
    setSigningUp(true);
    signUp(signUpDetails, redirectUrl)
      .then((done) => {
        completed();
        setSignUpMailSent(true);
      })
      .catch((e) => {
        setSignUpError(true);
      })
      .finally((done) => setSigningUp(false));
  }

  return (
    <div className="container">
      <div id="signup-form">
        <h2>Sign Up</h2>
        {!signUpMailSent && (
          <form onSubmit={signUpUser}>
            <div className="input-group">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Full Name"
                maxLength={50}
                onChange={(e) =>
                  setSignUpDetails({ ...signUpDetails, name: e.target.value })
                }
                required
              />
            </div>

            <div className="input-group">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email Id"
                onChange={(e) =>
                  setSignUpDetails({ ...signUpDetails, email: e.target.value })
                }
                required
              />
            </div>
            {signUpError && (
              <>
                <label className="error">
                  Unable to Sign Up. Please try again.
                </label>
                <br />
                <br />
              </>
            )}
            <button
              type="submit"
              className="subscribe-button"
              disabled={
                !signUpDetails.name || !signUpDetails.email || signingUp
              }
            >
              Sign Up
            </button>
            <p className="terms">
              By selecting Sign Up, I agree to Healthzify's{" "}
              <a
                href="https://drive.google.com/file/d/1DIbKTiT-_Xeya_ZUe25g3a1c4AFHOQ1s/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
              , and acknowledge the{" "}
              <a
                href="https://drive.google.com/file/d/1svMbSAMRfyaBINbHtGz0TfKTzYlOhBto/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy{" "}
              </a>
              .
            </p>
          </form>
        )}
        {!signUpMailSent && (
          <div className="login">
            <span>Already have an account? </span>
            <a href="/login">Log in</a>
          </div>
        )}
        {signUpMailSent && (
          <>
            <p id="signup-email-sent">
              You will receive an email from Healthzify, to the email provided
              by you. Please click on the link in the email and open it in this
              browser to confirm Sign Up.
            </p>
            <p>
              If you are facing any issues, please contact us at
              support@healthzify.com.
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default SignUp;
