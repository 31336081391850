import { motion } from "framer-motion";
import Button from "../../components/button";

const ReferralBanner = () => {
  const handleWhatsAppSend = () => {
    const message = `Just whipped up my weekly meal plan with Healthzify! It's customized just for my health needs and makes eating healthy a breeze!

    Give it a try? It's FREE for you this week and super easy to use!
    Check it out now! - www.healthzify.com`;
    const encodedMessage = encodeURIComponent(message);
    const url = `https://wa.me/?text=${encodedMessage}`;
    window.open(url, "_blank");
  };
  const bannerVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 120 },
    },
  };

  return (
    <motion.div
      className="referral-banner"
      initial="hidden"
      animate="visible"
      variants={bannerVariants}
      style={{
        background: "linear-gradient(45deg, #6CC1FF, #3A8DFF)",
        color: "white",
        padding: "10px 20px",
        fontSize: "1.2rem",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      Loved the meal plan? Invite your friends and help them on their health
      journey!
      <br />
      <br />
      <div>
        <Button click={handleWhatsAppSend}>
          <i className="fa-solid fa-gift" style={{ marginRight: "10px" }}></i>
          Refer Now!
        </Button>
      </div>
    </motion.div>
  );
};

export default ReferralBanner;
