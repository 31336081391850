import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./burger.css";

const BurgerMenu = ({ isOpen, setIsOpen, isLoggedIn = false }) => {
  const menuRef = useRef();
  // Handle outside clicks
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false); // Close the menu if clicking outside
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);
  const menuVariants = {
    open: {
      x: 0,
      opacity: 1,
      transition: { stiffness: 300, damping: 30 },
    },
    closed: {
      x: "100%",
      opacity: 0,
      transition: { stiffness: 300, damping: 30 },
    },
  };

  return (
    <motion.div
      id="mobile-menu"
      ref={menuRef}
      variants={menuVariants}
      initial="closed"
      animate={isOpen ? "open" : "closed"}
    >
      <div className="brand-name">Healthzify</div>
      <ul>
        {isLoggedIn && (
          <li>
            <a href="/dashboard" onClick={() => setIsOpen(false)}>
              Dashboard
            </a>
          </li>
        )}
        {isLoggedIn && (
          <li>
            <a href="/my-plan" onClick={() => setIsOpen(false)}>
              My Plan
            </a>
          </li>
        )}
        {!isLoggedIn && (
          <li>
            <a href="/signup" onClick={() => setIsOpen(false)}>
              Sign Up
            </a>
          </li>
        )}
        {!isLoggedIn && (
          <li>
            <a href="/login" onClick={() => setIsOpen(false)}>
              Log In
            </a>
          </li>
        )}
        {isLoggedIn && (
          <li>
            <a href="/signout" onClick={() => setIsOpen(false)}>
              Logout
            </a>
          </li>
        )}
      </ul>
    </motion.div>
  );
};

export default BurgerMenu;
