const firebaseConfig = {
  apiKey: "AIzaSyCJpbPHk_f480wLzlXI8DJEMdpK4pWoyaI",
  authDomain: "hzify-15039.firebaseapp.com",
  databaseURL: "https://hzify-15039.firebaseio.com",
  projectId: "hzify-15039",
  storageBucket: "hzify-15039.appspot.com",
  messagingSenderId: "839835727762",
  appId: "1:839835727762:web:697c658e358e46d055734b",
  measurementId: "G-3ZQZC3503B",
};

export default firebaseConfig;
