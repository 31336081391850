import { motion, AnimatePresence } from "framer-motion";

const Modal = ({ isOpen, onClose, children }) => {
  // Define motion variants for animations
  const backdropVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const modalVariants = {
    hidden: { y: "-50vh", opacity: 0 },
    visible: {
      y: "0",
      opacity: 1,
      transition: { delay: 0.3, type: "spring", stiffness: 120 },
    },
  };

  // Prevent modal close function propagation to avoid unintended closures
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="backdrop"
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={onClose}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000, // High z-index to ensure it's on top of other content
          }}
        >
          <motion.div
            className="modal"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={stopPropagation}
            style={{
              margin: "auto",
              padding: "20px",
              background: "#fff",
              borderRadius: "10px",
              width: "80%",
              maxWidth: "640px", // Adjust modal width here
              textAlign: "center",
              position: "relative",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              overflowX: "hidden",
              maxHeight: "80vh",
            }}
          >
            <i
              className="fa-solid fa-xmark"
              onClick={onClose}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                cursor: "pointer",
                fontSize: "1.25em",
              }}
            ></i>

            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
