import { useState } from "react";

const Activity = ({ setInfo, next }) => {
  const [selection, updateSelection] = useState("");
  return (
    <form onSubmit={next}>
      <div className="activity-levels">
        <label>What is your current physical activity level?</label>
        <br />
        <br />
        <div
          className={`activity-level ${
            selection === "light" ? "selected" : ""
          }`}
          onClick={() => {
            updateSelection("light");
            setInfo({ activity_level: "light" });
          }}
        >
          <h3>Low activity</h3>
          <p>&le;2 hours per week</p>
        </div>
        <div
          className={`activity-level ${
            selection === "moderate" ? "selected" : ""
          }`}
          onClick={() => {
            updateSelection("moderate");
            setInfo({ activity_level: "moderate" });
          }}
        >
          <h3>Moderate activity</h3>
          <p>3-5 hours per week</p>
        </div>
        <div
          className={`activity-level ${
            selection === "active" ? "selected" : ""
          }`}
          onClick={() => {
            updateSelection("active");
            setInfo({ activity_level: "active" });
          }}
        >
          <h3>Active</h3>
          <p>6-8 hrs per week</p>
        </div>
        <div
          className={`activity-level ${
            selection === "veryactive" ? "selected" : ""
          }`}
          onClick={() => {
            updateSelection("veryactive");
            setInfo({ activity_level: "veryactive" });
          }}
        >
          <h3>Very Active</h3>
          <p>8+ hours per week</p>
        </div>
      </div>
      <button type="submit" disabled={!selection}>
        Next
      </button>
    </form>
  );
};

export default Activity;
