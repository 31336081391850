import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "Home Delivery": "Home Delivery",
          // other translations...
        },
      },
      hi: {
        translation: {
          "Subscribe Now": "सब्सक्राइब करें",
          "Akshayakalpa Amrutha A2 Farm Fresh Milk": "अक्षयकल्प अमृता ए2 फार्म ताजा दूध"
          // other translations...
        },
      },
    },
    lng: "en", // language to use, more languages can be added to resources
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
