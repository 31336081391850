const BodyInfo = ({setInfo, next}) => (
    <form onSubmit={next}>
       <div>
            <label>Gender</label><br/>
            <input type="radio" id="m" name="gender" value="male" onChange={(e) => setInfo({age:e.target.value}) } required/>
            <label htmlFor="m">Male</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input type="radio" id="f" name="gender" value="female" onChange={(e) => setInfo({age:e.target.value}) } required/>
            <label htmlFor="f">Female</label>
        </div>
        <div>
            <label>Age</label><br/>
            <input type="number" placeholder="Age" onChange={(e) => setInfo({age:e.target.value}) } required/>
        </div>
        <div>
            <label>Weight (kg)</label><br/>
            <input type="number" placeholder="Weight (in kg)" onChange={(e) => setInfo({weight:e.target.value}) } required/>
        </div>
        <div>
            <label>Height (cm)</label><br/>
            <input type="number" placeholder="Height (in cm)" onChange={(e) => setInfo({height:e.target.value}) } required/>
        </div>
        <div>
            <label>City</label><br/>
            <input type="text" placeholder="Which city are you from?" onChange={(e) => setInfo({city:e.target.value}) } required/>
        </div>
        <button type="submit" >Next</button>
    </form>
);

export default BodyInfo;