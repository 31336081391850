import "./home.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Playstore from "../../img/google-play-badge.png";
import Supermarket from "../../img/in-store.webp";
import BMI from "../../img/bmi.jpg";
import RacePlan from "../../img/race.jpg";
import Chips from "../../img/chips.jpg";
import Noodles from "../../img/noodles.jpg";
import Drink from "../../img/soft-drink.jpeg";
import Sparkle from "../../img/sparkle.png";
import Search from "../../components/search";
import Profile from "../../img/profile.png";
import Down from "../../img/chevron-down.png";
import Newsletter from "./newsletter";
import BurgerMenu from "../../components/header/burger";
import { useTranslation } from "react-i18next";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function Home() {
  //const [searchItem, setSearchItem] = useState("");
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const searchProduct = (searchItem) => {
    //if (e.key === "Enter" && searchItem) {
    window.gtag &&
      window.gtag("event", "search", {
        event_category: "button",
        event_label: "search",
        query: searchItem,
      });
    navigate("/listing?search=" + searchItem);
    //}
  };
  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        navigate("/dashboard");
      }
    });
    //   let externalScript;
    //   setTimeout(() => {
    //     externalScript = document.createElement("script");
    //     externalScript.src = "https://platform.twitter.com/widgets.js";
    //     externalScript.defer = true;
    //     externalScript.charSet = "utf-8";
    //     document.head.append(externalScript);
    //   }, 500);
    //   return () => {
    //     externalScript && externalScript.remove();
    //   };
  }, []);

  const { t } = useTranslation();

  const searchQuery = (e) => {
    if (e.target && e.target.getAttribute("data-query-value")) {
      searchProduct(e.target.getAttribute("data-query-value"));
    }
  };

  return (
    <>
      <div id="home">
        <section id="hero">
          {/* <div className="blur"> */}
          <div className="hero">
            <nav>
              {/* <a
                href="https://play.google.com/store/apps/details?id=com.healthzify"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Playstore} alt="Get the App" />
              </a> */}

              <a
                className="sign-up-btn"
                href="/signup"
                onClick={(e) => {
                  e.preventDefault();
                  window.gtag &&
                    window.gtag("event", "click", {
                      event_category: "button",
                      event_label: "sign up",
                      referring_page: "home",
                    });
                  navigate("/signup");
                }}
              >
                Sign Up
              </a>

              <a
                className="subscribe-now"
                href="/subscribe"
                onClick={(e) => {
                  e.preventDefault();
                  window.gtag &&
                    window.gtag("event", "click", {
                      event_category: "button",
                      event_label: "home delivery",
                    });
                  navigate("/subscribe");
                }}
              >
                {t("Home Delivery")}
              </a>

              <a
                className="profile"
                href=""
                onClick={(e) => e && e.preventDefault()}
              >
                <img src={Profile} />
                &nbsp;&nbsp;
                <img className="icon-down" src={Down} />
                <ul>
                  <>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        window.gtag &&
                          window.gtag("event", "click", {
                            event_category: "button",
                            event_label: "sign up",
                            referring_page: "profile header",
                          });

                        navigate("/signup");
                      }}
                    >
                      Sign Up
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        window.gtag &&
                          window.gtag("event", "click", {
                            event_category: "button",
                            event_label: "log in",
                            referring_page: "profile header",
                          });

                        navigate("/login");
                      }}
                    >
                      Log In
                    </li>
                  </>
                </ul>
              </a>

              <span className="mobile-menu">
                <BurgerMenu
                  isOpen={isMenuOpen}
                  setIsOpen={setMenuOpen}
                  isLoggedIn={false}
                />
                <span onClick={() => setMenuOpen(!isMenuOpen)}>
                  <i className="fa-solid fa-bars"></i>
                </span>
              </span>
            </nav>
            <div className="brand-name">Healthzify</div>
            <h1 className="tagline">A healthy life starts with healthy food</h1>
            <Search searchProduct={searchProduct} />
            <div id="query-suggestions" onClick={searchQuery}>
              <div className="suggestion" data-query-value="peanut butter">
                Best peanut butters
              </div>
              <div className="suggestion" data-query-value="evening snack">
                Evening snack suggestions
              </div>
              <div className="suggestion" data-query-value="Kissan Jam">
                Is Kissan Jam healthy?
              </div>
            </div>
            <a
              className="header-key-button meal-plan"
              href="/meal-plan"
              onClick={(e) => {
                e.preventDefault();
                window.gtag &&
                  window.gtag("event", "click", {
                    event_category: "button",
                    event_label: "ai meal plan",
                  });
                navigate("/meal-plan");
              }}
            >
              <img src={Sparkle} />
              &nbsp;Get AI Meal Plan
            </a>
          </div>
        </section>
        <section id="key-categories">
          <ul>
            <li style={{ backgroundColor: "#564f42" }}>
              <a href="/listing/evening-snack">Evening Snack</a>
            </li>
            <li style={{ backgroundColor: "#f59396" }}>
              <a href="/listing/morning-snack">Morning Snack</a>
            </li>
            <li style={{ backgroundColor: "#f6a61e" }}>
              <a
                href="/listing/breakfast"
                style={{ width: "100%", height: "100%" }}
              >
                Breakfast
              </a>
            </li>
            <li style={{ backgroundColor: "#44ae92" }}>
              <a href="/listing/lunch">Lunch</a>
            </li>
            <li style={{ backgroundColor: "#355dd5" }}>
              <a href="/listing/dinner">Dinner</a>
            </li>
            <li style={{ backgroundColor: "#c54b0f" }}>
              <a href="/listing/dessert">Dessert</a>
            </li>
            {/* <li style={{ backgroundColor: "#fb931c" }}>
              <a href="/listing/juices">Juices</a>
            </li> */}
            <li style={{ backgroundColor: "#728e47" }}>
              <a href="/listing/workout">Workout</a>
            </li>
            <li style={{ backgroundColor: "#fb931c" }}>
              <a href="/listing/super">Super Basket</a>
            </li>
          </ul>
        </section>
        <section id="find-alternatives">
          <a href="/product/CocaCola-Diet-Coke-Soft-Drink?tab=alternatives">
            <img src={Drink} />
            <br />
            <span>Alternatives for Soft Drinks</span>
          </a>
          <a href="/product/Lays-Potato-Chips--Indias-Magic-Masala?tab=alternatives">
            <img src={Chips} />
            <br />
            <span>Alternatives for Chips</span>
          </a>
          <a href="/product/Maggi-2Minute-Instant-Noodles--Masala?tab=alternatives">
            <img src={Noodles} />
            <br />
            <span>Alternatives for Instant Noodles</span>
          </a>
        </section>
        <section id="benefit">
          {/* Health and nutrition at your fingertips
      Say yes to healthy living
      Say yes to a healthier life
      Its now easy to make the healthy choice
      Making the right choice is now easy 
      Making the healthy choice is now easy  */}

          <div>
            <h2>Making the healthy choice is now easy</h2>
            <p>
              Unlock more features on the Healthzify app and start ordering now
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.healthzify"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Playstore} alt="Get the App" />
            </a>
          </div>

          <img src={Supermarket} alt="" />
        </section>
        <section id="misc">
          <div className="fitness-tools">
            <h2>What gets measured gets managed</h2>
            <div className="tools">
              <div>
                <a href="/bmi" className="card" target="_blank">
                  <img src={BMI} />
                  <div>
                    <div className="title">BMI Calculator</div>
                    BMI is quick method to screen for health risks.
                    <br />
                    <br />
                    <button>Check Now</button>
                  </div>
                </a>
              </div>
              <div>
                <a
                  href="https://www.precisionhydration.com/planner/"
                  className="card"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={RacePlan} />
                  <div>
                    <div className="title">Race Fuel Planner</div>
                    Crush your next race with a personalised fuel & hydration
                    plan.
                    <br />
                    <button>Let's Go</button>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* <a
            className="twitter-timeline"
            data-height="600"
            data-dnt="true"
            href="https://twitter.com/healthzify?ref_src=twsrc%5Etfw"
            style={{ width: "100%" }}
          >
            Tweets by Healthzify
          </a> */}
        </section>
        <section id="newsletter">
          <Newsletter />
        </section>
      </div>
    </>
  );
}

export default Home;
