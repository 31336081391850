import { useState } from "react";
import Modal from "../../components/modal";
import Button from "../../components/button";

function EditMeal({
  isModalOpen,
  toggleModal,
  item = {},
  updatePlan,
  updateStatus,
}) {
  const [updateItems, setUpdateItems] = useState(item.meals);
  return (
    <Modal isOpen={isModalOpen} onClose={toggleModal}>
      <h3>{item.day}</h3>
      {["breakfast", "lunch", "snacks", "dinner"].map((m, index) => (
        <div className="meal" key={index}>
          <label>{m}</label>

          <textarea
            style={{ width: "60%" }}
            rows="5"
            onChange={(e) => {
              setUpdateItems({
                ...updateItems,
                [m]: [e.target.value],
              });
            }}
            defaultValue={item.meals[m].join("\r")}
          ></textarea>
        </div>
      ))}
      <br />
      <Button
        click={() => updatePlan(item.day, updateItems)}
        disabled={updateStatus}
      >
        {updateStatus ? "Saving..." : "Save"}
      </Button>
    </Modal>
  );
}

export default EditMeal;
