import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";
import Plan from "./plan";
import StatusBanner from "../../components/statusbanner";
import { getUserInfo, updateMealPlan } from "../../services";
import Loader from "../../img/loader.gif";
import Sparkle from "../../img/sparkle.png";
import MealPlanHeader from "../../img/meal-plan.png";
import "./index.css";

function MyPlan() {
  const navigate = useNavigate();
  document.title = "My Plan";
  const [plan, setPlan] = useState({});
  const [plan_status, setPlanStatus] = useState();
  const [apiStatus, setApiStatus] = useState();
  useEffect(() => {
    async function getPlan() {
      try {
        const user = await getUserInfo();
        let user_plan = {};

        if (user.meal_plan && Object.keys(user.meal_plan.plan).length) {
          for (let day of Object.keys(user.meal_plan.plan)) {
            let d = day.toLowerCase();
            user_plan[d] = user.meal_plan.plan[day];
          }

          setPlan({
            meal_plan: user_plan,
            nutrition: user.meal_plan.nutrition,
          });
          setPlanStatus("success");
        } else {
          setPlanStatus("none");
        }
      } catch (e) {
        setPlanStatus("error");
      }
    }
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        getPlan();
      } else {
        navigate("/");
      }
    });
  }, []);

  function updatePlan(day, items, completed) {
    let new_plan = { ...plan };
    new_plan.meal_plan[day] = items; //includes nutrition for the day
    setApiStatus();
    updateMealPlan(new_plan.meal_plan, day)
      .then((updated_plan) => {
        new_plan.meal_plan = updated_plan;
        setPlan(new_plan);
        setApiStatus("Successfully saved changes.");
      })
      .catch((e) => {
        setApiStatus("Unable to save changes.");
      })
      .finally((done) => completed());
  }

  return (
    <>
      <Header subscription={false} mealPlan={false} signUp={false} />
      <div id="my-plan">
        {plan_status && plan_status !== "none" ? (
          <Plan
            plan={{ ...plan.meal_plan }}
            nutrition={{ ...plan.nutrition }}
            status={plan_status}
            updatePlan={updatePlan}
          />
        ) : plan_status === "none" ? (
          <div className="card no-plan">
            <img src={MealPlanHeader} alt="Meal Plan" />
            <h3>You do not have a plan yet.</h3>
            <p>Let's get started!</p>
            <a
              className="header-key-button ai-meal-plan"
              href="/meal-plan"
              onClick={(e) => {
                e.preventDefault();
                window.gtag &&
                  window.gtag("event", "click", {
                    event_category: "button",
                    event_label: "ai meal plan",
                  });
                navigate("/meal-plan");
              }}
            >
              <img src={Sparkle} />
              &nbsp;Get AI Meal Plan
            </a>
          </div>
        ) : (
          <img className="loader" src={Loader} alt="loading..." />
        )}
      </div>
      {apiStatus && (
        <StatusBanner
          messages={apiStatus}
          type={
            apiStatus.toLowerCase().indexOf("success") >= 0
              ? "success"
              : "error"
          }
        />
      )}
    </>
  );
}

export default MyPlan;
