import { useState } from "react";
import EditMeal from "./edit-meal";
import ReferralBanner from "./referral";

const Plan = ({ plan = {}, nutrition, status = "", updatePlan }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modifyItem, setModifyItem] = useState({});
  const [updateStatus, setUpdateStatus] = useState();
  const updateMyPlan = (day, item) => {
    let plan = item;
    for (let i in item) {
      if (i != "total" && item[i].length === 1) {
        plan[i] = item[i][0].split("\n");
      }
    }

    setUpdateStatus(true);
    updatePlan(day, plan, () => {
      setUpdateStatus(false);
      toggleModal();
    });
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  return (
    <div id="my-meal-plan">
      {status === "success" && (
        <div>
          <p style={{ marginBottom: 15, lineHeight: 1.4 }}>
            Your weekly plan includes the right mix of macros - carbohydrates,
            protein, fats, and fiber customised for your life. It also
            incorporates a healthy mix of micros like antioxidants, magnesium,
            iron etc that help you in sleep, muscle restoration, and more.
          </p>
          <div className="days">
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((d, index) => (
              <div key={index}>
                <div className="day">
                  <h3>{d}</h3>
                  <span
                    className="edit"
                    onClick={() => {
                      setModifyItem({
                        day: d,
                        meals: plan[d],
                      });
                      toggleModal();
                    }}
                  >
                    Edit
                  </span>
                </div>
                <label>Breakfast</label>
                <ul>
                  {(plan[d]["breakfast"] || plan[d]["Breakfast"]).map(
                    (food, i) => {
                      return <li key={i}>{food}</li>;
                    }
                  )}
                </ul>
                <div className="meal">
                  <label>Lunch</label>
                </div>
                <ul>
                  {(plan[d]["lunch"] || plan[d]["Lunch"]).map((food, i) => {
                    return <li key={i}>{food}</li>;
                  })}
                </ul>
                <div className="meal">
                  <label>Snacks</label>
                </div>
                <ul>
                  {(plan[d]["snacks"] || plan[d]["Snacks"]).map((food, i) => {
                    return <li key={i}>{food}</li>;
                  })}
                </ul>
                <div className="meal">
                  <label>Dinner</label>
                </div>
                <ul>
                  {(plan[d]["dinner"] || plan[d]["Dinner"]).map((food, i) => {
                    return <li key={i}>{food}</li>;
                  })}
                </ul>
                {plan[d]["total"] && (
                  <div>
                    <label
                      style={{ display: "inline-block", marginBottom: 10 }}
                    >
                      Nutrition for the day
                    </label>
                    <br />
                    <label>Macros</label>
                    <ul>
                      {plan[d]["total"]["calories"] && (
                        <li>
                          Total Calories: {plan[d]["total"]["calories"]} Cal
                        </li>
                      )}

                      <li>Protein: {plan[d]["total"]["protein"]}</li>
                      <li>
                        Carbohydrates: {plan[d]["total"]["carbohydrates"]}
                      </li>
                      <li>Fats: {plan[d]["total"]["fats"]}</li>
                    </ul>
                    {nutrition && (
                      <>
                        <label>Micros</label>
                        <ul>
                          {plan[d]["total"]["vitaminC"] && (
                            <li>
                              Vitamin C: {plan[d]["total"]["vitaminC"]}{" "}
                              {nutrition["vitaminC"] && (
                                <span>(of {nutrition["vitaminC"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["vitaminD"] && (
                            <li>
                              Vitamin D: {plan[d]["total"]["vitaminD"]}{" "}
                              {nutrition["vitaminD"] && (
                                <span> (of {nutrition["vitaminD"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["vitaminA"] && (
                            <li>
                              Vitamin A: {plan[d]["total"]["vitaminA"]}{" "}
                              {nutrition["vitaminA"] && (
                                <span> (of {nutrition["vitaminA"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["vitaminB12"] && (
                            <li>
                              Vitamin B12: {plan[d]["total"]["vitaminB12"]}{" "}
                              {nutrition["vitaminB12"] && (
                                <span> (of {nutrition["vitaminB12"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["vitaminE"] && (
                            <li>
                              Vitamin E: {plan[d]["total"]["vitaminE"]}{" "}
                              {nutrition["vitaminE"] && (
                                <span> (of {nutrition["vitaminE"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["calcium"] && (
                            <li>
                              Calcium: {plan[d]["total"]["calcium"]}{" "}
                              {nutrition["calcium"] && (
                                <span> (of {nutrition["calcium"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["iron"] && (
                            <li>
                              Iron: {plan[d]["total"]["iron"]}{" "}
                              {nutrition["iron"] && (
                                <span> (of {nutrition["iron"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["magnesium"] && (
                            <li>
                              Magensium: {plan[d]["total"]["magnesium"]}{" "}
                              {nutrition["magnesium"] && (
                                <span> (of {nutrition["magnesium"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["zinc"] && (
                            <li>
                              Zinc: {plan[d]["total"]["zinc"]}{" "}
                              {nutrition["zinc"] && (
                                <span> (of {nutrition["zinc"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["potassium"] && (
                            <li>
                              Potassium: {plan[d]["total"]["potassium"]}{" "}
                              {nutrition["potassium"] && (
                                <span> (of {nutrition["potassium"]})</span>
                              )}
                            </li>
                          )}
                          {plan[d]["total"]["omega3"] && (
                            <li>
                              Omega3: {plan[d]["total"]["omega3"]}{" "}
                              {nutrition["omega3"] && (
                                <span> (of {nutrition["omega3"]})</span>
                              )}
                            </li>
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="more-info">
            <ul className="card">
              <li>
                Adjust or replace some items if it helps make it easier for you
                to source and adhere.
              </li>
              <li>
                Some nutrition values will change based on your ingredient
                quality and cooking methods. Values are suggestive in nature and
                not strict guidelines.
              </li>
              <li>
                Drink at least 2 litres of water daily on a regular day where
                you do not have lot of physical activity. If you have a higher
                activity on a given day, you will neeed to take in at least
                2.5-3L of water.{" "}
              </li>
              <li>
                If you have any health issues, please consult your doctor before
                implementing this plan.
              </li>
            </ul>
            <ul className="card">
              <li>
                For suggestions on brands and alternatives, just{" "}
                <a style={{ textDecoration: "underline" }} href="/dashboard">
                  search
                </a>{" "}
                our catalog and find the right brands and alternatives on the
                product page.
              </li>
              <li>
                You can also mail us at support@healthzify.com and we would love
                to help you out anytime.
              </li>
            </ul>
          </div>
          <ReferralBanner />
          <br />
        </div>
      )}
      {status === "error" && (
        <div className="card">
          <p>
            <br />
            Sorry! We are currently unable to get your plan.
            <br />
            Please refresh the page and try again or come back after some time.
            <br />
            <br />
            If the problem persists, mail us at support@healthzify.com and we
            will send your plan via email.
          </p>
        </div>
      )}
      {isModalOpen && (
        <EditMeal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          item={modifyItem}
          updatePlan={updateMyPlan}
          updateStatus={updateStatus}
        />
      )}
    </div>
  );
};

export default Plan;
