import "./footer.css";
import instagram from "../../img/instagram-32.png";
import twitter from "../../img/twitter-32.png";
import facebook from "../../img/facebook-32.png";
import mail from "../../img/mail-24.png";
import playstore from "../../img/google-play-badge.png";

function Footer() {
  return (
    <>
      <footer>
        <div className="info">
          <div className="brand-name">Healthzify</div>
          <div>
            <ul className="social">
              <li>
                <a
                  href="https://www.instagram.com/healthzify/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/healthzify?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/healthzify/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} />
                </a>
              </li>
              <li>
                <a
                  href="mailto:support@healthzify.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="support@healthzify.com"
                >
                  <img src={mail} />
                </a>
              </li>
            </ul>
          </div>
          {/* <a
            className="app-link"
            href="https://play.google.com/store/apps/details?id=com.healthzify"
            target="_blank"
            rel="noreferrer"
          >
            <img src={playstore} alt="Get the App" />
          </a> */}
        </div>
        <div className="terms">
          <div>Contact Us: support@healthzify.com</div>
          <br></br>
          <div>
            <a
              href="https://drive.google.com/file/d/1DIbKTiT-_Xeya_ZUe25g3a1c4AFHOQ1s/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://drive.google.com/file/d/1svMbSAMRfyaBINbHtGz0TfKTzYlOhBto/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              Privacy
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="/sitemap.xml" target="_blank" rel="noreferrer">
              Sitemap
            </a>
          </div>
          <div style={{ marginTop: 5 + "px" }}>© 2024 by Healthzify</div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
