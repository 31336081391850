import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Header from "../../components/header";
import "./index.css";
import Basket from "../../img/basket.png";

import { subscribeToService } from "../../services";

function Subscription() {
  const [subscribed, setSubscribtionStatus] = useState(false);
  const [subscribing, setSubscribingStatus] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    document.title = "Healthzify: Home Delivery";
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setAuthenticated(true);
        setUser(user);
      }
    });
  }, []);
  const subscribe = (e) => {
    e.preventDefault();
    document.getElementById("message").textContent = "";
    let name = isAuthenticated
      ? user.displayName
      : document.getElementById("name").value;
    let email = isAuthenticated
      ? user.email
      : document.getElementById("email").value;
    if (validateEmail(email)) {
      setSubscribingStatus(true);
      window.gtag &&
        window.gtag("event", "click", {
          event_category: "button",
          event_label: "subscribe newsletter",
          type: isAuthenticated ? "current-user" : "non-user",
        });

      subscribeToService(name, email)
        .then(() => {
          setSubscribtionStatus(true);
          setSubscribingStatus(false);
          document.getElementById("message").textContent =
            "Thank you! We will let you know as soon as we are ready. Coming very soon!";
          document.getElementById("message").style.color = "green";
        })
        .catch((error) => {
          setSubscribingStatus(false);
          console.error("Error adding document: ", error);
          document.getElementById("message").textContent =
            "Error subscribing. Please try again.";
          document.getElementById("message").style.color = "red";
        });
    } else {
      document.getElementById("message").textContent =
        "Please enter a valid email address.";
      document.getElementById("message").style.color = "red";
    }
  };
  function validateEmail(email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }
  return (
    <div id="subscription">
      <div className="head-section">
        <Header subscription={false} showBack={true} />
      </div>
      <div className="subscription-form-container">
        <div>
          <div className="subscription-form">
            <h2>Subscribe</h2>
            <p>
              Get a weekly supply of amazing products at your doorstep.
              Subscribe for more details.
              <br />
              <br />
              Transform the trajectory of your health and life with just one
              small step.
            </p>

            {!subscribed && (
              <form id="weekly-subscription-form">
                {!isAuthenticated && (
                  <>
                    <div className="input-group">
                      <label htmlFor="name">Name</label>
                      <input type="text" id="name" name="name" required />
                    </div>

                    <div className="input-group">
                      <label htmlFor="email">Email</label>
                      <input type="email" id="email" name="email" required />
                    </div>
                  </>
                )}
                <button
                  type="submit"
                  className="subscribe-button"
                  onClick={subscribe}
                  disabled={subscribing}
                >
                  Join Waitlist
                </button>
              </form>
            )}
            <div id="message"></div>
          </div>
        </div>
        <div>
          <img
            style={{ width: "100%" }}
            src={Basket}
            alt="Weekly Curated Basket"
          />
        </div>
      </div>
    </div>
  );
}

export default Subscription;
