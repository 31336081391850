export function removeURLParameters(url, parametersToRemove) {
  // Split the URL into base URL and query string
  var [baseUrl, queryString] = url.split("?");

  if (!queryString) {
    // If there's no query string, return the original URL
    return url;
  }

  // Parse the query string into key-value pairs
  var params = new URLSearchParams(queryString);

  // Remove each specified parameter from the parameters object
  parametersToRemove.forEach((parameter) => {
    params.delete(parameter);
  });

  // Reconstruct the URL with the modified parameters
  var newUrl = baseUrl + "?" + params.toString();

  return newUrl;
}

export function extractPath(url) {
  const regex = /https?:\/\/[^\/]+(\/.*)?/;
  const match = url.match(regex);
  return match && match[1] ? match[1] : "/";
}
