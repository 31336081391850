import "./App.css";
import "./normalize.css";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrolltotop";
import Subscription from "./pages/subscribe";
import "./i18n";
import MealPlan from "./pages/meal-plan";
import SignUp from "./pages/signup";
import LogIn from "./pages/login";
import SignOut from "./components/signout";
import Dashboard from "./pages/dashboard";
import MyPlan from "./pages/my-plan";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const DownloadAppHeader = lazy(() => import("./components/downloadapp"));
const ProductListing = lazy(() => import("./pages/product-listing"));
const Product = lazy(() => import("./pages/product"));

function App() {
  // const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // let isAndroid = false;
  // if (/android/i.test(userAgent)) {
  //   isAndroid = true;
  // }

  onAuthStateChanged(getAuth(), (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      window.location.path = "/signout";
    }
  });

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        {/* {isAndroid && <DownloadAppHeader />} */}
        <div style={{ minHeight: "60vh" }}>
          <Suspense fallback={<></>}>
            <Routes>
              <Route exact path="/" element={<Home />} />

              <Route
                path="/listing/:itemtype"
                element={<ProductListing />}
              ></Route>
              <Route path="/signup" element={<SignUp />}></Route>
              <Route path="/login" element={<LogIn />}></Route>
              <Route path="/signout" element={<SignOut />}></Route>
              <Route path="/listing" element={<ProductListing />}></Route>
              <Route path="/product/:productName" element={<Product />}></Route>
              <Route path="/subscribe" element={<Subscription />}></Route>
              <Route path="/meal-plan" element={<MealPlan />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/my-plan" element={<MyPlan />}></Route>
              <Route path="/">{/* <Home /> */}</Route>
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
