import { useState } from "react";

const Goal = ({ setInfo, next }) => {
  const [selection, updateSelection] = useState("");
  return (
    <form onSubmit={next}>
      <div className="goals">
        <label>What is your goal?</label>
        <br />
        <br />
        <div
          className={`goal ${selection === "weight-loss" ? "selected" : ""}`}
          onClick={() => {
            updateSelection("weight-loss");
            setInfo({ goal: "weight-loss" });
          }}
        >
          <h3>Weight loss</h3>
        </div>
        <div
          className={`goal ${selection === "muscle-gain" ? "selected" : ""}`}
          onClick={() => {
            updateSelection("muscle-gain");
            setInfo({ goal: "muscle-gain" });
          }}
        >
          <h3>Muscle gain</h3>
        </div>
        <div
          className={`goal ${
            selection === "improve-fitness" ? "selected" : ""
          }`}
          onClick={() => {
            updateSelection("improve-fitness");
            setInfo({ goal: "improve-fitness" });
          }}
        >
          <h3>Improve overall fitness</h3>
        </div>
        <div
          className={`goal ${
            selection === "maintain-body-composition" ? "selected" : ""
          }`}
          onClick={() => {
            updateSelection("maintain-body-composition");
            setInfo({ goal: "maintain-body-composition" });
          }}
        >
          <h3>Maintain current body composition</h3>
        </div>
      </div>
      <button type="submit" disabled={!selection}>
        Next
      </button>
    </form>
  );
};

export default Goal;
